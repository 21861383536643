import { createStore, useStore as baseUseStore } from 'vuex';

export enum LanguageEnv {
  CN = 'cn',
  EN = 'en',
}

interface LanguageState {
  env: LanguageEnv;
}

interface RooteState {
  language: LanguageState
}

export default createStore<RooteState>({
  state: {
    language: {
      env: LanguageEnv.CN,
    },
  },
  getters: {
  },
  mutations: {
    changeLangEnv(state, env: LanguageEnv) {
      state.language.env = env;
    },
  },
  actions: {
  },
  modules: {
  },
});

export function useStore() {
  return baseUseStore<RooteState>();
}
