export enum PageName {
  Home = '首页',
}

export enum PageModuleName {
  HomeAd = '首页-广告图',
  HomeInfo = '首页-泉果资讯',
  HomeMeetOrigin = '首页-相遇泉果',
  HomePrivateReserve = '首页-专户预约',
  HomeBannerCarousel = '首页轮播图',
}

export interface PageModuleItem {
  remark: string; // 素材id, 如articleId
  imgUrl: string; // 封面图
  position: PageModuleName; // 位置
  skipUrl: string; // 跳转链接
}

export interface FundListFundCard {
  fundManager: string;
  recommendContent: string;
  incrementRatio: string;
  fundName: string;
  fundCode: string;
  backImagePath: string;
  dataShowFlag: string;
  fundDataLabel: string;
  fundDataValue: string;
}

export const AppDownloadQrCodeImage = 'https://www.qgfund.com/qg/image/20220728/1658970870577fd349ba47881483eabbc71f3b76a55ca.png';
