import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27817c16"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "page-container",
  id: "page-container"
}
const _hoisted_2 = {
  class: "main-view",
  id: "main-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom_bar = _resolveComponent("bottom-bar")!
  const _component_FloatButtons = _resolveComponent("FloatButtons")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, {
      direction: "column",
      style: {"height":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, { class: "header" }, {
          default: _withCtx(() => [
            _createVNode(_component_header_bar)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view),
          _createVNode(_component_bottom_bar)
        ]),
        _createVNode(_component_FloatButtons)
      ]),
      _: 1
    })
  ]))
}