import { FundNewsType } from '@/interfaces/Article';
import { FundGroup } from '@/interfaces/Fund';
import { post, get } from '@/utils/Request/index';

export const getFundTypeList = (fundGroup: FundGroup) => post({
  url: '/getFundTypeList',
  body: {
    fundGroup,
  },
});

export const getFundList = ({
  fundGroup,
  fundSubGroup,
  pageSize = 100,
  pageNum = 1,
}: {
  fundGroup?: FundGroup;
  fundSubGroup?: string;
  pageSize?: number;
  pageNum?: number;
}) => post({
  url: '/fundList',
  body: {
    fundGroup,
    fundSubGroup,
    pageSize,
    pageNum,
  },
});

export const getFundBaseInfo = ({
  fundCode,
}: {
  fundCode: string;
}) => post({
  url: '/getFundBaseInfo',
  body: {
    fundCode,
  },
});

// 基金详情信息
export const getAllFundInfo = (fundCode: string) => post({
  url: '/fundInfo',
  body: {
    fundCode,
  },
});

// 基金产品描述 (开放规则等)
export const getFundDesc = (fundCode: string) => post({
  url: '/fundDetail',
  body: {
    fundCode,
  },
});

export const getFundMarketInfoList = ({
  fundCode,
  queryType = 'ALL',
  pageNum,
  pageSize,
}: {
  fundCode: string;
  queryType?: string;
  pageNum?: number;
  pageSize?: number;
}) => post({
  url: '/getFundMarketInfoList',
  body: {
    fundCode,
    queryType,
    pageNum,
    pageSize,
  },
});

// 基金业绩
export const getFundHistory = (fundCode: string) => post({
  url: '/getFundHistoryList',
  body: {
    fundCode,
  },
});

export const getAllotAmountLimit = ({
  bizScene,
  productCode,
  shareType = 'A',
}: {
  bizScene: string;
  productCode: string;
  shareType?: string;
}) => post({
  url: '/getAllotAmountLimit',
  body: {
    autoBuyFlag: false,
    bizScene,
    productCode,
    shareType,
  },
});

// 基金标识查询
export const getFundLabel = (fundCode: string) => post({
  url: '/getFundFlag',
  body: {
    body: {
      fundCode,
    },
    head: {},
  },
});

// 新发基金信息
export const getSubscribeFundInfo = ({
  fundCode,
  preFlag,
}: {
  fundCode: string;
  preFlag: string;
}) => post({
  url: '/fundSubscribeInfo',
  body: {
    fundCode,
    preFlag,
  },
});

// 新发基金费率
export const getFundSubscribeRateInfo = ({
  fundCode,
  preFlag,
}: {
  fundCode: string;
  preFlag: string;
}) => post({
  url: '/getPreHeatRate',
  body: {
    fundCode,
    preFlag,
  },
});

// 基金信息-费率结构
export const getTradeDeal = (fundCode: string) => post({
  url: '/getTradeDeal',
  body: {
    fundCode,
  },
});

// 获取基金分红历史
export const getFundDividend = (fundCode: string) => post({
  url: '/getFundBonus',
  body: {
    fundCode,
  },
});

// 获取销售机构
export const getAgencyList = (fundCode: string) => post({
  url: '/getAgencyList',
  body: {
    fundCode,
    pageNum: 1,
    pageSize: 2000,
  },
});

// 投资观点
export const getHoldViewPoint = ({
  fundCode,
  newsType,
  quarter,
}: {
  fundCode: string;
  newsType: FundNewsType;
  quarter?: string;
}) => post({
  url: '/getFundNews',
  body: {
    fundCode,
    newsType,
    quarter,
  },
});

export const getFundPortfolio = ({
  fundCode,
  quarter,
}: {
  fundCode: string;
  quarter: string;
}) => post({
  url: '/getFundportfolio',
  body: {
    fundCode,
    quarter,
  },
});

/** 获取基金热门问题 */
export const getQuestionListByFundCode = (fundCode: string) => get({
  url: '/ng/question/getQuestionListByFundCode',
  params: {
    fundCode,
  },
});

/** 获取基金披露季度时间列表 */
export const getFundPortfolioQuarters = (fundCode: string) => post({
  url: '/getFundPortfolioQuarters',
  body: {
    fundCode,
  },
});
