import { Article } from '@/interfaces/Article';
import { Router } from 'vue-router';

export const articleDirect = (router: Router, articleItem: Article | null | undefined) => {
  if (!articleItem) {
    return;
  }
  const { articleId, outsideUrl } = articleItem;
  if (outsideUrl) {
    window.open(outsideUrl);
    return;
  }
  if (articleId) {
    router.push({
      name: 'article',
      query: {
        articleId,
      },
    });
  }
};
