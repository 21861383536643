import { post, get } from '@/utils/Request/index';

// 获取页面模块配置
export const getPageConfig = (menu: string) => post({
  url: '/getPicture',
  body: {
    menu,
  },
});

// 获取验证码
export const getVerifyCode = ({
  requestNo,
  requestTime,
}: {
  requestNo: string;
  requestTime: string;
}) => post({
  url: '/img/generate',
  body: {
    requestNo,
    requestTime,
    requestSystem: 'H5',
  },
});

// 验证码验证
export const verifyCode = ({
  code,
  codeKey,
}: {
  code: string;
  codeKey: string;
}) => post({
  url: '/sms/send',
  body: {
    code,
    codeKey,
  },
});

// 发送预约申请
export const sendReservationCode = ({
  sex,
  investRole,
  lastName,
  firstName,
  email,
  city,
  captcha,
}: {
  sex: number;
  investRole: number;
  lastName: string;
  firstName: string;
  email: string;
  city: string;
  captcha: string;
}) => post({
  url: '/order',
  body: {
    sex,
    investRole,
    lastName,
    firstName,
    email,
    city,
    captcha,
  },
});

/** 全局搜索 */
export const globalSearch = (keyWord: string) => post({
  url: '/ng/search/globalSearch',
  body: {
    keyWord,
  },
});

/** 热推基金 */
export const getRecommendFundList = () => get({
  url: '/ng/recommend/getFundList',
  params: {},
});
