
import { Options, Vue } from 'vue-class-component';
import HeaderBar from '@/components/Layout/HeaderBar/index.vue';
import BottomBar from '@/components/Layout/BottomBar/index.vue';
import FloatButtons from '@/components/Layout/FloatButtons/index.vue';

@Options({
  components: {
    HeaderBar,
    BottomBar,
    FloatButtons,
  },
})
export default class AppClass extends Vue {}
