import { createApp } from 'vue';
import LoadingWrap from '@/components/Common/DefaultLoading/index.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import elementPluginInit from './plugins/element';

const app = createApp(App);

elementPluginInit(app);

app.component('LoadingWrap', LoadingWrap);

app
  .use(store)
  .use(router)
  .mount('#app');
