import { PageModuleName, PageModuleItem } from '@/interfaces/Page';

// 提取页面模块配置
export const getModuleConfig = (
  moduleName: PageModuleName,
  list: PageModuleItem[],
) => list.find((el: PageModuleItem) => el.position === moduleName);

export const UploadFilePathPrefix = process.env.VUE_APP_ENV === 'development' ? 'http://10.8.96.106' : 'https://www.qgfund.com';

export const getFullUploadFilePath = (path: string) => `${UploadFilePathPrefix}${path}`;

export const getImagePath = (path: string) => {
  if (!path) {
    return '';
  }
  if (path.indexOf('http') > -1) {
    return path;
  }
  return `${process.env.VUE_APP_ENV === 'production' ? 'https://app.qgfund.com/static/images/' : ''}${path}`;
};
