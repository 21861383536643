import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
} from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  }, {
    path: '/fund',
    name: 'funddetail',
    component: () => import(
      /** webpackChunkName: "FundDetail" */
      '@/views/FundDetail.vue'
    ),
  }, {
    path: '/fundlist',
    name: 'fundlist',
    component: () => import(/** webpackChunkName: "FundList" */'@/views/FundList.vue'),
  }, {
    path: '/service',
    name: 'service',
    component: () => import(/** webpackChunkName: "Service" */'@/views/Service.vue'),
  }, {
    path: '/service/detail',
    name: 'service-detail',
    component: () => import(/** webpackChunkName: "ServiceDetail" */'@/views/ServiceDetailView.vue'),
  }, {
    path: '/announcement',
    name: 'announcement',
    component: () => import(/** webpackChunkName: "AnnouncementPage" */'@/views/Announcement.vue'),
  }, {
    path: '/zhuanhu',
    name: 'zhuanhu',
    component: () => import(/** webpackChunkName: "VipPage" */'@/views/Vip.vue'),
  }, {
    path: '/insights',
    name: 'insights',
    component: () => import(/** webpackChunkName: "Insights" */'@/views/Insights.vue'),
  }, {
    path: '/insights/list',
    name: 'insights-list',
    component: () => import(/** webpackChunkName: "InsightsList" */'@/views/InsightsList.vue'),
  }, {
    path: '/article',
    name: 'article',
    component: () => import(/** webpackChunkName: "ArticleContent" */'@/views/ArticleContent.vue'),
  }, {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import(/** webpackChunkName: "AboutUs" */'@/views/AboutUs.vue'),
  }, {
    path: '/aboutus-en',
    name: 'aboutus-en',
    component: () => import(/** webpackChunkName: "AboutUs" */'@/views/AboutUs.vue'),
    meta: {
      isEnglish: true,
    },
  }, {
    path: '/search',
    name: 'search',
    component: () => import(/** webpackChunkName: "SearchPage" */'@/views/SearchResult.vue'),
  }, {
    path: '/relatednews',
    name: 'related-news',
    component: () => import(/** webpackChunkName: "RelatedNewsPage" */'@/views/RelatedNews.vue'),
  }, {
    path: '/qualification',
    name: 'qualification',
    component: () => import(/** webpackChunkName: "QualificationPage" */'@/views/Qualification.vue'),
  }, {
    path: '/policy',
    name: 'policy',
    component: () => import(/** webpackChunkName: "PolicyPage" */'@/views/Policy.vue'),
  }, {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const mainView = document.documentElement;
  if (mainView) {
    mainView.scrollTo(0, 0);
  }
  next();
});

export default router;
