export enum FundState {
  New = 'b', // 新发基金
  Normal = '0', // 正常
  Publish = '1', // 发行
  PublishSuccess = '2', // 发行成功
  PublishFail = '3', // 发行失败
  TradeStop = '4', // 停止交易
  SubscribeStop = '5', // 停止申购
  RedeemStop = '6', // 停止赎回
  EquityRegistration = '7', // 权益登记
  DividendDistribution = '8', // 红利发放
  ClosedFund = '9', // 基金封闭
  FundEnd = 'a', // 基金终止
}

export const FundStateName = {
  [FundState.New]: '新发基金',
  [FundState.Normal]: '正常',
  [FundState.Publish]: '发行',
  [FundState.PublishSuccess]: '发行成功',
  [FundState.PublishFail]: '发行失败',
  [FundState.TradeStop]: '停止交易',
  [FundState.SubscribeStop]: '停止申购',
  [FundState.RedeemStop]: '停止赎回',
  [FundState.EquityRegistration]: '权益登记',
  [FundState.DividendDistribution]: '红利发放',
  [FundState.ClosedFund]: '基金封闭',
  [FundState.FundEnd]: '基金终止',
};

export enum FundType {
  All = '0', // 全部
  Empty = '1', // --
  Index = 'F', // 指数型
  StockOrientedMix = 'B', // 偏股混合
  BondBalance = 'C', // 股债平衡
  FOF = '6', // FOF
  PensionFOF = '7', // 养老FOF
  BondOrientedMix = 'D', // 偏债混合
  Bond = 'E', // 债券型
  StockOriented = 'G', // 偏股型
  BondOriented = 'H', // 偏债型
  Quant = 'I', // 量化型
  // Mix = 'N', // 混合型
}

export const FundTypeName = {
  [FundType.All]: '全部',
  [FundType.Empty]: '--',
  [FundType.Index]: '指数型',
  [FundType.StockOrientedMix]: '偏股混合',
  [FundType.BondBalance]: '股债平衡',
  [FundType.FOF]: 'FOF',
  [FundType.PensionFOF]: '养老FOF',
  [FundType.BondOrientedMix]: '偏债混合',
  [FundType.Bond]: '债券型',
  [FundType.StockOriented]: '偏股型',
  [FundType.BondOriented]: '偏债型',
  [FundType.Quant]: '量化型',
};

export enum FundGroup {
  PublicFund = '0', // 公募基金
}

export enum RiskLevel {
  L = '0',
  L2M = '1',
  M = '2',
  M2H = '3',
  H = '4',
}

export const riskLevelMap = {
  [RiskLevel.L]: 'R1-低风险',
  [RiskLevel.L2M]: 'R2-中低风险',
  [RiskLevel.M]: 'R3-中风险',
  [RiskLevel.M2H]: 'R4-中高风险',
  [RiskLevel.H]: 'R5-高风险',
};

export interface FundManager {
  avatarUrl: string;
  introduction: string;
  managerId: string;
  managerName: string;
  workDate: string;
}

export interface FundItemInfo {
  dailyIncrease: string; // 日涨跌幅
  date: string; // 净值公布日
  discountRate: string; // 打折
  finishDate: string;
  foundIncrease: string; // 成立以来涨跌幅
  fundCode: string; // 基金代码
  fundName: string; // 基金名称
  fundState: FundState; // 基金状态
  fundLabel: string[]; // 标签
  fundSubGroupName: string; // 基金分类
  fundManagerList: FundManager[], // 基金经理列表
  fundType: FundType; // 基金类型
  incomeRatio: string;
  incomeUnit: string;
  nav: string; // 净值
  redeemState: string; // 赎回状态
  riskLevel: RiskLevel;
  subscribeState: string; // 认购状态
  totalNav: string; // 累计净值
  fundCustodian: string;
  daliyIncrease: string;
  fundManager: string;
  fundManagerNames: string;
  fundShort: string;
}

export interface FundTypeItem {
  fundGroup: string; // 大分类
  fundGroupCode: FundGroup; // 大分类代码
  id: string;
  levelOfRisk: string; // 基金分类中文名称
  levelOfRiskCode: string; // 基金分类代码
}

export interface FundMarketInfo {
  date: string;
  nav: string;
  totalNav: string;
  dayinc: string;
}

export interface FundDividendInfo {
  dregisterdate: string; // 权益登记日
  ddistributedate: string; // 红利发放日
  funitprofit: string; // 每10份基金分配红利金额
}

// 基金标识
export enum FundLabel {
  UnavailableSubscribe = '0', // 不可认购
  PreHeat = '1', // 预热
  SubscribeFinish = '2', // 认购结束待成立
}

export enum FundAssetItemType {
  Display = '1', // 外显
  Detail = '0', // 详细信息
}

interface FundAssetDetailGroup {
  detailName: string;
  detailValue: string;
}

export interface FundAsset {
  portfolioKey: string;
  portfolioValue: string;
  portfolioAmount: string;
  illustrateFlag: FundAssetItemType;
  detailGroups?: FundAssetDetailGroup[];
  type: string;
}

// 债券持仓 xbrl
export interface FundDebt {
  portfolioKey: string;
  portfolioValue: string;
  portfolioAmount: string;
  illustrateFlag: FundAssetItemType;
  publishDate: string;
  type: string;
}

export interface FundPortfolio {
  assets: FundAsset[];
  hongKongStockIndustryList: FundAsset[];
  commonStockIndustryList: FundAsset[];
  stocks: FundAsset[];
  publishDate: string;
  debts: FundDebt[];
  bondTypeList: FundDebt[];
}

export interface RateInfo {
  area: string; // 金额区域
  ratio: string;
  maxFare: string; // 最大单笔费用
}

export interface FundRateInfo {
  purchaseRate: RateInfo[]; // 申购费率
  subscriptionRate: RateInfo[]; // 认购费率
  redemptionRate: string; // 赎回费率（场内和场外）
  managementRate: string; // 管理费
  custodyRate: string; // 托管费率
  salesServiceRate: string; // 销售服务费率
  termPurchaseRate: string; // 后端申购费率
  termSubscriptionRate: string; // 后端认购费率
  standardRate?: string; // 待使用
  discountRate?: string; // 待使用
  addManagementRate?: string; // 待使用
  insiteredeemRate?: string; // 待使用
}
