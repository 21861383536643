import { post } from '@/utils/Request/index';

// 获取重要文章列表, 如消息通知
export const getImportantArticleList = ({
  articlePid,
  articleId,
}: {
  articlePid: string;
  articleId: string;
}) => post({
  url: '/articleTop',
  body: {
    articlePid,
    articleId,
    topFlag: 'Y',
  },
});

// 获取文章列表
export const getArticleList = ({
  articlePid,
  articleId,
  pageNum,
  pageSize = 10,
}: {
  articlePid: string;
  articleId: string;
  pageNum: number;
  pageSize: number;
}) => post({
  url: '/articleList',
  body: {
    articlePid,
    articleId,
    pageNum,
    pageSize,
    recommendFlag: 'Y',
  },
});

// 获取专栏文章
export const getColumnArticle = (articleId: string) => post({
  url: '/articleManeger',
  body: {
    articleId,
  },
});

// 获取文章内容
export const getArticleDetail = (articleId: string) => post({
  url: 'articleDetail',
  body: {
    articleId,
  },
});
