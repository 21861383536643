import EventType from '@/utils/Events/EventType';
import EventEmitter from 'event-emitter';

class MessageBoardcast {
  private listener: EventEmitter.Emitter = EventEmitter();

  on(key: EventType, callback: (...args: any[]) => void) {
    this.listener.on(key, callback);
  }

  off(key: EventType, callback: (...args: any[]) => void) {
    this.listener.off(key, callback);
  }

  trigger(key: EventType, ...args: any[]) {
    this.listener.emit(key, args);
  }
}

export default new MessageBoardcast();
