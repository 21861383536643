export enum ArticleFirstType {
  // TODO 不知道具体定义
  One = '1',
  Two = '2',
  Policy = '3', // 政策法规
  Four = '4', // 相关资质
}

export enum ArticleSecondType {
  Information = '1', // 资讯服务
  CustomerService = '2', // 客户服务
  InformationDisclosure = '3', // 信息披露
  RelatedNews = '4', // 相关报道
  SelfService = '5', // 自助服务
  Advice = '6', // 投诉建议
  Reminder = '7', // 服务提示
  PrivateQuestion = '8', // 常见问题(专户)
  Download = '30', // 下载专区
  OnlineDeal = '51', // 网上交易
  PublicQuestion = '70', // 常见问题(公募)
  Personnel = '92', // 人员资质
  Equity = '93', // 股权结构
  CompanyCertification = '94', // 公司资质
  InvestorManage = '111', // 投资者适当性管理
  AML = '112', // 反洗钱
  Policy = '113', // 其他法规
  InsightsOfOrigin = '151', // 泉果视点
  Accompany = '191', // 陪伴服务
}

export interface ArticleAttachment {
  fileName: string;
  filePath: string;
  originalName: string;
}

export interface Article {
  articleId: string;
  contentRtf: string; // 排版内容
  cover: string; // 封面
  editorType: string;
  publishTime: string; // 发布日期
  summary: string; // 摘要
  tagList: string[]; // 标签
  title: string; // 标题
  attachMent: ArticleAttachment[];
  outsideUrl: string; // 外链地址
  attachmentFileList: ArticleAttachment[]; // 附件
}

export enum FundNewsType {
  Accompany = 'accompany', // 持仓观点
  Cover = 'cover', // 腰封, 投资观点
}

export interface FundHoldView {
  content: string;
  icon: string; // 标识文字
  jumpType: string;
  openUrl: string;
  subTitle: string;
  title: string;
  fundNewsId: string;
}

export interface NoticeInfo {
  fundCode: string;
  noticeTitle: string;
  noticeUrl: string;
  publishDate: string;
}
