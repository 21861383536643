import axios from 'axios';

const service = axios.create({
  baseURL: '/qg/api/portal/official',
  timeout: 10000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const post = ({
  url,
  body,
}: {
  url: string;
  body: any;
}) => service.post(url, body);

export const get = ({
  url,
  params,
}: {
  url: string;
  params: any;
}) => service.get(url, { params });
